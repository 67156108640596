<script lang="ts" setup>
import { computed, onMounted, getCurrentInstance } from "vue";
import { getWeiiBldDecrees } from "@/services/api/masterdata.api";
import _ from "lodash";
import useGrid27 from "@/composables/grid27.js";
import router from "@/router/index";

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 0;

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Id", value: "id", width: 350 },
  { text: "Naam", value: "name" },
  { text: "Omschrijving", value: "descr" },
];
const headers = computed(() => _headers);

onMounted(() => {
  fetchFirstPage();
});

// eslint-disable-next-line no-unused-vars
async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const response = await getWeiiBldDecrees();
  return gridDataFromIdName(response.data, sortBy, sortDesc);
}

function gridDataFromIdName(data, sortBy, sortDesc) {
  let sortedData;
  if (sortBy) {
    sortedData = _.orderBy([...data.weiiBldDecrees], [sortBy], [sortDesc ? "desc" : "asc"]);
  } else {
    sortedData = _.orderBy([...data.weiiBldDecrees], ["name"], ["asc"]);
  }
  return { data: { data: sortedData, totalNumber: sortedData.length, lastPage: 0, currentPage: 1 } };
}

async function editItem(id) {
  //console.log("WeiiBuildingDecree editItem, key: ", id);
  router.push({
    name: "WeiiBuildingDecree",
    params: { id: id },
  });
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <v-layout class="inner">
    <header class="app-welcome-message">
      <h1>WeiiBuildingDecree</h1>
      <p>Weii Klasse</p>
    </header>
    <v-container fluid pa-0 mt-4>
      <v-row>
        <v-col cols="auto">
          <v-data-table
            dense
            :options.sync="gridOptions"
            :headers="headers"
            hide-default-footer
            :items="gridData"
            @update:options="changedOptions"
            :loading="gridIsLoading"
            :items-per-page="pageSize"
            :server-items-length="totalItems"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item.id)"> mdi-pencil </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
